import React, { Component } from 'react';
import * as Styled from 'components/OfflineEntries/styled';
import { Tooltip } from '@material-ui/core';
import { HeaderItem } from 'components/TimeEntryPanel/styled';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { MenuProvider } from 'react-contexify';
import ImmutableTimer from '../../api/immutables/ImmutableTimer';
import TimerPanelItemStore from 'store/Timer/timerPanelItem.store';
import { inject, observer } from 'mobx-react';
import { RootStore } from 'store/root.store';
import { parseCode } from '../../util/utils';
import { TimerChunk } from '../../api/types/types';
import { getDurStringBetween } from 'components/TimerChunkItem/TimerChunkItem';
import * as ReactDOM from 'react-dom';
import scrollIntoView from 'scroll-into-view-if-needed';
import { getTimerDurationTxt } from 'util/timer';
import { withTranslation } from 'react-i18next';

interface PanelProps {
    timer: ImmutableTimer;
    timerChunks: TimerChunk[] | undefined;
    timerPanelItemStore?: TimerPanelItemStore;
    includeSubmitted: boolean;
    // tslint:disable-next-line:no-any
    t: any;
}
interface TimerState {
    durTxt: string;
}
@inject((allStores: { rootStore: RootStore }) => {
    let rootStore = allStores.rootStore;
    return {
        timerPanelItemStore: rootStore.timerPanelItemStore
    }
})

@observer
class TimerPanelItem extends Component<PanelProps, TimerState> {
    interval: NodeJS.Timeout;

    constructor(props: PanelProps) {
        super(props);
        this.state = { durTxt: '' };
        if (props.timer.active) {
            this.interval = setInterval(this.timerTick, 250);
        }
    }
    
    async componentWillMount() {
        const tempId = this.props.timer.templateId;
        const matterId = this.props.timer.matterId;
        if (tempId) {
            await this.props.timerPanelItemStore!.setTimerTemplate(tempId);
        }
        if (matterId) {
            await this.props.timerPanelItemStore!.setTimerMatter(matterId);
        }
        clearInterval(this.interval);
    }
    
    expandTimerPanel = (id: number | undefined) => {
        if (id) {
            let expanded = this.props.timerPanelItemStore!.expandedTimerPanelIds.slice();
            if (expanded.includes(id)) {
                expanded = expanded.filter((s) => s !== id)
            } else {
                expanded.push(id);
            }
            this.props.timerPanelItemStore!.setExpendedTimerPanels(expanded);
            if (expanded.includes(id)) {
                setTimeout(() => {
                    let elem: HTMLElement = ReactDOM.findDOMNode(this) as HTMLElement;
                    scrollIntoView(elem, { behavior: 'smooth', scrollMode: 'if-needed' })
                }, 0);
            }
        } else {
            return;
        }
    }

    timerTick = () => {
        const { timer, includeSubmitted } =  this.props;
        this.setState({ durTxt: getTimerDurationTxt(timer, includeSubmitted) });
    }
    
    render() {
        const { timer, timerChunks, includeSubmitted, t } = this.props;
        const { timerTemplate, timerMatter, expandedTimerPanelIds } = this.props.timerPanelItemStore!;
        const templateName = timerTemplate ? timerTemplate.name : '';
        const matterDetails = timerMatter ? parseCode(timerMatter.number, timerMatter.name) : '';
        const expanded = expandedTimerPanelIds.includes(timer.id!);
        const timerText = timer.active ? this.state.durTxt : getTimerDurationTxt(timer, includeSubmitted);
        
        return (
            <div>
                <Styled.Container
                    elevation={1}
                    square={true}
                    expanded={expanded}
                    type={'timer'}
                >
                    <MenuProvider id={`menu_${timer.id}`} >
                        <Styled.OfflineHeader
                            type={'timer'}
                            onClick={() => this.expandTimerPanel(timer.id)}
                        >
                            <Tooltip title={timer.name}>
                                <HeaderItem>{timer.name}</HeaderItem>
                            </Tooltip>
                            <HeaderItem>{timerText}</HeaderItem>
                            <Tooltip title={templateName}>
                                <HeaderItem>{templateName}</HeaderItem>
                            </Tooltip>
                            <Tooltip title={matterDetails}>
                                <HeaderItem>{matterDetails}</HeaderItem>
                            </Tooltip>
                            {expanded ? <div title={t('collapse')}><KeyboardArrowUp/></div>
                                : <div title={t('expand')}><KeyboardArrowDown/></div>}
    
                        </Styled.OfflineHeader>
                    </MenuProvider>
                </Styled.Container>
                {expanded &&
                <Styled.TimerChunksContainer square={true}>
                    {timerChunks ?
                        <div>
                            {
                                timerChunks.map((chunk) =>
                                    <Styled.OfflineHeader type={'chunk'} key={chunk.id as number}>
                                        <HeaderItem>
                                            {(new Date(chunk.startTime)).toLocaleDateString()}
                                        </HeaderItem>
                                        <HeaderItem>
                                            {getDurStringBetween(new Date(chunk.startTime), new Date(chunk.endTime))}
                                        </HeaderItem>
                                        <Tooltip title={chunk.description}>
                                            <HeaderItem>
                                                {chunk.description}
                                            </HeaderItem>
                                        </Tooltip>
                                    </Styled.OfflineHeader>
                                )
                            }
                        </div> :
                        <></>
                    }
                </Styled.TimerChunksContainer>
                }
            </div>
        );
    }
}

export default withTranslation(['common'])(TimerPanelItem);