import { DateTime } from 'luxon';
import { ActionCode, Code, CodeSetFlags, Features, Matter } from 'api/types/types';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import { ValidationState } from 'api/immutables/validators';
import { parseCode } from './utils';
import rootStore from 'store/root.store';

export const setMatter = async (
    m: Matter | null,
    timeEntry: TimeEntry,
    onChange: (t: TimeEntry, newVState?: ValidationState) => void,
    validationState?: ValidationState,
    isGridView: boolean = false
) => {
    const { api } = rootStore;
    let newVState,
        entry = timeEntry;
    if (m) {
        rootStore.homeStore.setFieldLoaderFn(true);
        onChange(entry.setMatter(m));
        if (!m.tracked) {
            await api.Matter.track([m.id]);
        }
        const codeSetFlags: CodeSetFlags = await api.Code.determineCodeSetFields(m.id, entry.workDateTime);
        m.isPhaseCode = codeSetFlags.isPhaseCode;
        m.isFfTaskCode = codeSetFlags.isFfTaskCode;
        m.isActCode = codeSetFlags.isActCode;
        if (validationState) {
            newVState = validationState!.clone();
            newVState.missing.matter = false;
        }
        if (codeSetFlags.phases.length === 1) {
            let phase = codeSetFlags.phases[0];
            entry = entry.setPhase(phase);
            let tasks = await api.Code.getTaskCodes(phase.id, entry.workDateTime, '');
            if (tasks.length === 1) {
                let task = tasks[0];
                entry = entry.setTask(task);
                entry = rootStore.timeEntryStore.setNarrativeFromCodes(entry, task);
            }
            if (newVState) {
                newVState.missing.phase = false;
                newVState.missing.task = false;
            }
        }
        if (codeSetFlags.ffTasks.length === 1) {
            let ffTask = codeSetFlags.ffTasks[0];
            entry = entry.setFFTask(ffTask);
            let ffActs = await api.Code.getFFActCodes(ffTask.id, entry.workDateTime, '');
            if (ffActs.length === 1) {
                let ffAct = ffActs[0];
                entry = entry.setFFAct(ffAct);
            }
            if (newVState) {
                newVState.missing.ffTask = false;
                newVState.missing.ffAct = false;
            }
        }
        if (codeSetFlags.activities.length === 1) {
            let act = codeSetFlags.activities[0];
            entry = entry.setAct(act);
            entry = rootStore.timeEntryStore.setNarrativeFromCodes(entry, act);
            if (newVState) {
                newVState.missing.activity = false;
            }
        }
        // Call API only when Action Code is already selected.
        if (timeEntry.actionCodeObj) {
            let actionObj = await api.Code.getActionCodes( m!.id, timeEntry.actionCode);
            entry = entry.setActionCode(actionObj[0]);
        }
    } else if (isGridView) {
        entry = entry.setClient(null);
    }
    entry = entry.setNarrative(timeEntry.narrative!);
    if (!isGridView) {
        entry = entry.setDuration(timeEntry.duration!);
    }
    onChange(entry.setMatter(m), newVState);
    rootStore.homeStore.setFieldLoaderFn(false);
}

export const setNarrative = (
    text: string,
    timeEntry: TimeEntry,
    onChange: (t: TimeEntry, newVState?: ValidationState) => void,
    validationState?: ValidationState
) => {
    let newVState;
    let entry = timeEntry.setNarrative(text);
    if (validationState) {
        newVState = validationState!.clone();
        newVState.narrativeBannedWords = [];
        newVState.narrativeBlockBillingWords = [];
        newVState.narrativeLength = false;
        if (text.length >= rootStore.timeEntryDialogStore.minNarrativeLength) {
            newVState.narrativeLength = false;
        }
        if (text.length <= rootStore.timeEntryDialogStore.maxNarrativeLength) {
            newVState.maxNarrativeLength = false;
        }
    }
    onChange(entry, newVState);
}

export const setPhase = async (
    c: Code | null,
    timeEntry: TimeEntry,
    onChange: (t: TimeEntry, newVState?: ValidationState) => void,
    validationState?: ValidationState
) => {
    let newVState;
    let entry = timeEntry.setPhase(c);
    if (validationState) {
        newVState = validationState!.clone();
        newVState.missing.phase = false;
    }
    
    if (c) {
        let tasks = await rootStore.api.Code.getTaskCodes(c.id, entry.workDateTime, '');
        if (tasks.length === 1) {
            let task = tasks[0];
            entry = entry.setTask(task);
            entry = rootStore.timeEntryStore.setNarrativeFromCodes(entry, task);
        }
        if (newVState) {
            newVState.missing.task = false;
        }
    }
    onChange(entry, newVState);
}

export const setTask = (
    c: Code | null,
    timeEntry: TimeEntry,
    onChange: (t: TimeEntry, newVState?: ValidationState) => void,
    validationState?: ValidationState,
) => {
    let newVState;
    let entry = timeEntry.setTask(c);
    if (c) {
        entry = rootStore.timeEntryStore.setNarrativeFromCodes(entry, c);
    }
    if (validationState) {
        newVState = validationState!.clone();
        newVState.missing.task = false;
    }
    onChange(entry, newVState);
}

export const setAct = (
    c: Code | null,
    timeEntry: TimeEntry,
    onChange: (t: TimeEntry, newVState?: ValidationState) => void,
    validationState?: ValidationState
) => {
    let newVState;
    let entry = timeEntry.setAct(c);
    if (c) {
        entry = rootStore.timeEntryStore.setNarrativeFromCodes(entry, c);
    }
    if (validationState) {
        newVState = validationState!.clone();
        newVState.missing.activity = false;
    }
    onChange(entry, newVState);
}

export const setFFTask = async (
    c: Code | null,
    timeEntry: TimeEntry,
    onChange: (t: TimeEntry, newVState?: ValidationState) => void,
    validationState?: ValidationState
) => {
    let newVState;
    let entry = timeEntry.setFFTask(c);
    if (validationState) {
        newVState = validationState!.clone();
        newVState.missing.ffTask = false;
    }
    if (c) {
        let ffActs = await rootStore.api.Code.getFFActCodes(c.id, entry.workDateTime, '');
        if (ffActs.length === 1) {
            let ffAct = ffActs[0];
            entry = entry.setFFAct(ffAct);
        }
        if (newVState) {
            newVState.missing.ffAct = false;
        }
    }
    onChange(entry, newVState);
}

export const setFFAct = (
    c: Code | null,
    timeEntry: TimeEntry,
    onChange: (t: TimeEntry, newVState?: ValidationState) => void,
    validationState?: ValidationState
) => {
    let newVState;
    let entry = timeEntry.setFFAct(c);
    if (validationState) {
        newVState = validationState!.clone();
        newVState.missing.ffAct = false;
    }
    onChange(entry, newVState);
}

export const setActionCode = (
    c: ActionCode | null,
    timeEntry: TimeEntry,
    onChange: (t: TimeEntry, newVState?: ValidationState) => void,
    validationState?: ValidationState
) => {
    let newVState;
    let entry = timeEntry.setActionCode(c);
    if (validationState) {
        newVState = validationState!.clone();
        if (c) {
            newVState.narrativeBannedWords = [];
            newVState.narrativeBlockBillingWords = [];
            newVState.missing.action = false;
            if (c!.actionText.length >= rootStore.timeEntryDialogStore.minNarrativeLength) {
                newVState.narrativeLength = false;
            }
            if (c!.actionText.length <= rootStore.timeEntryDialogStore.maxNarrativeLength) {
                newVState.maxNarrativeLength = false;
            }
        }
    }
    onChange(entry, newVState);
}

export const changeNarrativeLanguage = (
    key: string,
    timeEntry: TimeEntry,
    onChange: (t: TimeEntry, newVState?: ValidationState) => void    
) => {
    let entry = timeEntry.setBillingLanguage(key);
    onChange(entry);
}

export const fetchPhaseCodes = async (entry: TimeEntry, searchText?: string) => {
    let workDateTime = entry.workDateTime;
    let results = await rootStore.api.Code.getPhaseCodes(entry.matterId!, workDateTime, searchText);
    return results;
}

export const fetchTaskCodes = async (entry: TimeEntry, searchText?: string) => {
    let workDateTime = entry.workDateTime;
    let results = await rootStore.api.Code.getTaskCodes(entry.phaseId!, workDateTime, searchText);
    return results;
}

export const fetchActivityCodes = async (entry: TimeEntry, searchText?: string) => {
    let workDateTime = entry.workDateTime;
    let results = await rootStore.api.Code.getActivityCodes(entry.matterId!, workDateTime, searchText);
    return results;
}

export const fetchFFTaskCodes = async (entry: TimeEntry, searchText?: string) => {
    let workDateTime = entry.workDateTime;
    let results = await rootStore.api.Code.getFFTaskCodes(entry.matterId!, workDateTime, searchText);
    return results;
}

export const fetchFFActCodes = async (entry: TimeEntry, searchText?: string) => {
    let workDateTime = entry.workDateTime;
    let results = await rootStore.api.Code.getFFActCodes(entry.ffTaskCodeId!, workDateTime, searchText);
    return results;
}

export const fetchMatters = async (entry: TimeEntry, features: Features, searchText: string) => {
    let  results: Matter[] = await rootStore.api.Matter.searchMatters(
        searchText,
        true,
        entry.clientId!,
        DateTime.fromISO(entry.workDateTime).toISODate(),
        0,
        200
    );
    if (!features.EpochConfigTrackedMatterClientsEnabled && results.length === 0) {
        results = await rootStore.api.Matter.getAvailableMatters(
            searchText,
            false,
            entry.clientId!,
            DateTime.fromISO(entry.workDateTime).toISODate(),
            0,
            200
        );
    }
    return results;
}

export const getMatterTooltipText = (m: Matter) => {
    const matterLabel = rootStore.appStore.features.EpochConfigMatterLabel;
    if (matterLabel !== 'Job') {
        return m.description;
    }
    // this code not translated because only used when matterLabel=Job (NRF)
    const firstLine = `${matterLabel} MD: ${m.billingPartnerName || ''}`;
    const secondLine = `${matterLabel} Manager: ${m.billingManagerName || ''}`;
    const thirdLine = `${matterLabel} Office: ${m.billingOffice || ''}`;
    const forthLine = m.description;
    return `${firstLine}\n${secondLine}\n${thirdLine}\n${forthLine}`;
}

export const getClientText = (timeEntry: TimeEntry) => {
    const { client } = timeEntry;
    return client ? parseCode(client.number, client.name) : '';
}