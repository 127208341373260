// tslint:disable:max-line-length
import { action, computed, observable, makeObservable } from 'mobx';
import { DateTime, Duration } from 'luxon';
import { ViewMode } from 'containers/Home/Home.desktop';
import { RootStore } from 'store/root.store';
import TimeEntry from 'api/immutables/ImmutableTimeEntry';
import ImmutableTimeEntry from '../api/immutables/ImmutableTimeEntry';
import { getStartOf, getEndOfWeek } from 'components/Calendar/Calendar';
import TimeEntryManager from './managers/timeentry.manager';
import {
    CaptureSegmentsListObservables,
    DeprecatedDesktopCaptureData,
    DesktopCaptureData,
    Months,
    Setting,
    TimeCastProgram,
    TimeCastSegment,
    TimeCastSegmentType,
    TimerChunk,
    TkGoals,
    TkHours
} from '../api/types/types';
import { TimeCastSettings } from '../containers/TimeCastSettings/TimeCastSettings';
import {
    CALENDAR_EVENT_APPLICATION_NAME,
    CONVERSATION_HISTORY_APPLICATION_NAME,
    PHONE_CALL_APPLICATION_NAME,
    SENT_MAIL_APPLICATION_NAME,
    TimeCast
} from '../util/TimeCast';
import * as React from 'react';
import { ValidateSave } from '../api/immutables/validators';
import { debounce } from 'typescript-debounce-decorator';
import { ApiResult } from '../api/util';
import ImmutableTimer from '../api/immutables/ImmutableTimer';
import { Platform } from '../util/Platform';
import { AssociateApiResult } from '../api/interfaces/TimeEntryAPI';
import logger from '../logging/logging';

const constants = require('../constants.json');
let tabex = require('tabex');
export interface AggregateTotals {
    draft: number,
    posted: number,
    billable: number,
    nonbillable: number,
    billableDraft?: number,
    billablePosted?: number
}
export interface TimeCastSegmentInterval {
    startTime: DateTime
    endTime: DateTime
    gap: boolean // whether this is a gap interval
}

export interface TimeCastSegmentGap extends TimeCastSegmentInterval {
    gap: true
}

export interface TimeCastSegmentGroup extends TimeCastSegmentInterval {
    segments: TimeCastSegment[]
    groupId: number | string | null
    title: string | null
    gap: false
    application: string
    type: TimeCastSegmentType
    duration: number
}

export enum WindowsIntegratedProgramTypes {
    MS_WORD = 'MS_WORD',
    MS_EXCEL = 'MS_EXCEL',
    MS_POWERPOINT = 'MS_POWERPOINT',
    MS_ACCESS = 'MS_ACCESS',
    MS_PUBLISH = 'MS_PUBLISH'
}

export enum SegmentViewMode {
    APPLICATION_TYPE,
    CHRONOLOGICAL,
    TIME_TYPE
}

const DUMMY_PROGRAM: TimeCastProgram = {
    code: '',
    architecture: '',
    operatingSystem: '',
    id: -1,
    programName: 'Other',
    createdOn: '',
    knownExecutable: '',
    knownPath: '',
    lastModified: '',
    submittedBy: -1,
    color: '#333'
};

const DUMMY_PHONE_CALL_EVENT_PROGRAM: TimeCastProgram = {
    code: 'PHONE_CALL',
    architecture: '',
    operatingSystem: '',
    id: -1,
    programName: 'Phone Calls',
    createdOn: '',
    knownExecutable: '',
    knownPath: '',
    lastModified: '',
    submittedBy: -1,
    color: '#333'
};

const DUMMY_EXCHANGE_CALENDAR_EVENT_PROGRAM: TimeCastProgram = {
    code: 'CALENDAR_EVENT',
    architecture: '',
    operatingSystem: '',
    id: -1,
    programName: 'Calendar Events',
    createdOn: '',
    knownExecutable: '',
    knownPath: '',
    lastModified: '',
    submittedBy: -1,
    color: '#333'
};

const DUMMY_SENT_MAIL_PROGRAM: TimeCastProgram = {
    code: 'SENT_MAIL',
    architecture: '',
    operatingSystem: '',
    id: -1,
    programName: 'Sent Mail',
    createdOn: '',
    knownExecutable: '',
    knownPath: '',
    lastModified: '',
    submittedBy: -1,
    color: '#333'
};

// const DUMMY_CONVERSATION_HISTORY_PROGRAM: TimeCastProgram = {
//     code: '',
//     architecture: '',
//     operatingSystem: '',
//     id: -1,
//     programName: 'Phone Calls',
//     createdOn: '',
//     knownExecutable: '',
//     knownPath: '',
//     lastModified: '',
//     submittedBy: -1,
//     color: '#538AA6'
// };

export default class HomeStore extends TimeEntryManager {
    tabexClient = tabex.client();
    loc = DateTime.local();
    @observable mainCalendarDate: DateTime;
    @observable miniCalendarMainDate: DateTime;
    @observable calendarMode: ViewMode = ViewMode.MONTH;
    @observable selectedDates: DateTime[] = [DateTime.utc(this.loc.year, this.loc.month, this.loc.day)];

    @observable startDate: DateTime;
    @observable endDate: DateTime;
    @observable tkHours: TkHours | undefined;
    @observable tkGoals: TkGoals | undefined;

    // =======================================================================
    //  TimeCast Segments
    // =======================================================================
    @observable timeCastSettings?: TimeCastSettings;
    @observable allPrograms: TimeCastProgram[] = [];
    
    // Used for `SegmentViewMode.CHRONOLOGICAL`
    @observable segmentGroupsByChronology: Array<TimeCastSegmentGroup | TimeCastSegmentGap> = [];
    // Used for `SegmentViewMode.APPLICATION_TYPE`
    @observable segmentGroupsByApplication: Record<string, TimeCastSegmentGroup[]> = {};
    // Used for `SegmentiewMode.TIME_TYPE`
    @observable segmentGroupsByTime: Record<string, TimeCastSegmentGroup[]> = {};
    
    // View state
    @observable segmentsLoading = true;
    @observable selectedSegments: Array<TimeCastSegmentGroup | TimeCastSegmentGap> = [];
    @observable segmentViewMode: SegmentViewMode = SegmentViewMode.CHRONOLOGICAL;
    @observable _showSegmentGaps = true; // see `get showSegmentGaps() {}`
    @observable showUsedSegments = false;
    @observable expandedApplications = [] as string[]
    @observable expandedTimeGroups = [] as string[]
    @observable currentDaySegments: TimeCastSegment[] = [];
    @observable isTCViewInProgress: boolean = false;
    @observable toggleTimers: boolean = false;
    @observable dayViewTimers: ImmutableTimer[] = [];
    @observable expandedTimers = [] as number[]
    @observable selectedTimerSegments: Array<TimerChunk> = [];
    @observable matterLoading: boolean = false;

    @observable isTimersUpdating: boolean = false;
    tcRefreshable: boolean = false;
    // =======================================================================
    
    constructor(root: RootStore) {
        super(root);
        makeObservable(this);
        this.mainCalendarDate = DateTime.utc(this.loc.year, this.loc.month, this.loc.day);
        this.miniCalendarMainDate = this.mainCalendarDate;
        this.initializeHandler();
        this.updateTimersHandler();
        this.tabexClient.on('updatedTimersInHomePage', this.updateTimersForDay);
        this.rootStore.api.TimeCast.registerReceiverForSegments(this.receiveSegments);
        this.rootStore.api.TimeCast.registerReceiverForPrograms(this.receivePrograms);
        this.rootStore.api.Settings.registerReceiver(this.receiveSettings);
    }
    shouldRefreshTCSegments = (val: boolean) => {
        this.tcRefreshable = val;
    }
    updateTimersHandler = () => {
        if (this.rootStore.api.Timer.updateHomeChunksListener) {
            this.rootStore.api.Timer.updateHomeChunksListener(this.updateTimersForDay);
        }
    }

    // this will update the chunks
    updateTimersForDay = (timerChunks: TimerChunk[]) => {
        // this.renderTimerSegments(timerChunks);
        this.setTimersForDay();
    }
    
    public isToday(date: DateTime): boolean {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        return (date.year === year && date.month === month && date.day === day);
    }
    /*=======================================================================
     * TimeCast
     ======================================================================*/
    getProgram = (programCode: string): TimeCastProgram => {
        const program = this.allPrograms.find(p => p.code === programCode);
        if (program) {
            return program;
        } else if (programCode === CALENDAR_EVENT_APPLICATION_NAME) {
            return DUMMY_EXCHANGE_CALENDAR_EVENT_PROGRAM;
        } else if (programCode === PHONE_CALL_APPLICATION_NAME) {
            return DUMMY_PHONE_CALL_EVENT_PROGRAM;
        } else if (programCode === SENT_MAIL_APPLICATION_NAME) {
            return DUMMY_SENT_MAIL_PROGRAM;
        } else if (programCode === CONVERSATION_HISTORY_APPLICATION_NAME) {
            // return DUMMY_CONVERSATION_HISTORY_PROGRAM;
            return DUMMY_PHONE_CALL_EVENT_PROGRAM;
        }
        return DUMMY_PROGRAM;
    };
    
    getProgramColor = (programCode: string): string => {
        const program = this.getProgram(programCode);
        return program.color;
    }
    
    @action fetchTimeCastSettings = async (): Promise<void> => {
        if (!this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return;
        }
        this.allPrograms = await this.rootStore.api.TimeCast.allPrograms();
        let setting = await this.rootStore.api.Settings.getByKey(constants.timecast.settingKey);
        if (setting) {
            this.timeCastSettings = TimeCastSettings.fromSetting(setting);
        } else {
            this.timeCastSettings = TimeCastSettings.default();
        }
    }
    @action toggleApplicationExpansion = (programCode: string) => {
        const included = this.expandedApplications.findIndex(p => p === programCode);
        if (included === -1) {
            this.expandedApplications.push(programCode);
        } else {
            this.expandedApplications.splice(included, 1);
        }
        this.refreshSegmentsView();
    }
    @action toggleTimerExpansion = (id: number) => {
        const included = this.expandedTimers.findIndex(t => t === id);
        if (included === -1) {
            this.expandedTimers.push(id);
        } else {
            this.expandedTimers.splice(included, 1);
        }
        this.expandedTimers = this.expandedTimers.slice();
    }
    @action toggleTimeGroupExpansion = (groupHeader: string) => {
        const included = this.expandedTimeGroups.findIndex(p => p === groupHeader);
        if (included === -1) {
            this.expandedTimeGroups.push(groupHeader);
        } else {
            this.expandedTimeGroups.splice(included, 1);
        }
        this.refreshSegmentsView();
    }
    
    get showSegmentGaps(): boolean {
        return this._showSegmentGaps && this.segmentViewMode === SegmentViewMode.CHRONOLOGICAL;
    }
    
    get ungroupedSegments(): TimeCastSegment[] {
        if (!this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return [];
        }
        return this.segmentGroupsByChronology.reduce((prev, cur) => {
            if (!cur.gap) {
                prev.concat(cur.segments);
            }
            return prev;
        }, [] as TimeCastSegment[]);
    }
    @action updateTimeEntryFromDragSource = async (timeEntry: ImmutableTimeEntry) => {
        const collabs = this.rootStore.collaboratees;
        if (collabs.length > 0) {
            this.rootStore.confirmCollaborateDialogStore
                .open()
                .then(() => this._updateTimeEntryFromDragSource(timeEntry))
                .catch(() => {
                    return;
                });
        } else {
            await this._updateTimeEntryFromDragSource(timeEntry);
        }
    }
    checkPushNotsAndSync = () => {
        this.rootStore.api.Session.syncLastPushNotification();
    }
    _updateTimeEntryFromDragSource = async (timeEntry: ImmutableTimeEntry) => {
        const { api, appStore, snackbarStore } = this.rootStore;
        try {
            this.isTCViewInProgress = true;
            const tcSegments = this.selectedSegments.sort((sg1, sg2) => {
                return (sg1.startTime > sg2.startTime) ? 1 : -1;
            });
            const timerSegments = this.selectedTimerSegments.sort((sg1, sg2) => {
                return (DateTime.fromISO(sg1.startTime) > DateTime.fromISO(sg2.startTime)) ? 1 : -1;
            });
            const date = this.selectedDates[0];
            if (timeEntry.isPosted()) {
                await snackbarStore.triggerSnackbar(
                    'view.day.segment.drag_and_drop.validation.append_to_posted.snackbar',
                    { ns: 'home' }
                );
                this.isTCViewInProgress = false;
                return;
            }
            let entry = await this.setEntryFieldValues(date, timeEntry, tcSegments, timerSegments, this.dayViewTimers);
            // Reference field mandatory for some clients.
            if (appStore.features.EpochConfigReferenceRequired && appStore.features.EpochConfigTimeCastEnabled) {
                if (!entry.matterId) {
                    entry = entry.setReference('TimeCast');
                }
            }
            let vstate = ValidateSave(
                entry,
                await api.TimeEntry.getTotalForDateExclusive(date.toISO(), [entry.id!]),
                appStore.features,
                appStore.getActiveTimeKeeperForDate(DateTime.fromISO(entry.workDateTime))
            );
            if (vstate.duration) {
                snackbarStore.isError = true;
                snackbarStore.triggerSnackbar(
                    'view.day.segment.drag_and_drop.validation.total_duration_24.snackbar',
                    { ns: 'home' }
                );
                this.isTCViewInProgress = false;
                return;
            }
            if (vstate.invalidWorkDate) {
                snackbarStore.isError = true;
                snackbarStore.triggerSnackbar('validation.date.invalid', { ns: 'timeentries' });
                this.isTCViewInProgress = false;
                return;
            }
            let toSaveTCSegments: TimeCastSegment[] = [];
            this.selectedSegments.filter(s => !s.gap).map(group => {
                (group as TimeCastSegmentGroup).segments.forEach(segment => {
                    if (!segment.deleted) {
                        toSaveTCSegments.push(segment)
                    }
                });
            });
            const apiResult = await api.TimeEntry.associateSegmentsToEntry(
                entry,
                this.selectedTimerSegments,
                toSaveTCSegments
            );
            this.associatedCallback(apiResult);
            this.selectedSegments = [];
            this.selectedTimerSegments = [];
            this.isTCViewInProgress = false;
        } catch (e) {
            logger.error('Time Entries, Updating Time Entry From Drag Source failed.\n', e);
        }
    }
    @action receiveSettings = (settings: Setting[]) => {
        if (this.rootStore.appStore.features && !this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return;
        }
        const setting = settings.find(s => s.key === constants.timecast.settingsKey);
        if (setting) {
            this.timeCastSettings = TimeCastSettings.fromSetting(setting);
            this.timeCastSettings.getMinimumIntervalSeconds();
        }
    }

    @action receivePrograms = async (programs: TimeCastProgram[]) => {
        if (this.rootStore.appStore.features && !this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return;
        }
        this.allPrograms = await this.rootStore.api.TimeCast.allPrograms();
    }
    
    @action receiveSegments = (segments: TimeCastSegment[]) => {
        if (this.rootStore.appStore.features && !this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return;
        }
        if (Platform.isWeb()) {
            this.renderSegments(segments);
        }
        // do nothing
    };

    @action toggleSegmentGaps = () => {
        if (!this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return;
        }
        this._showSegmentGaps = !this._showSegmentGaps;
    };

    @action toggleShowProcessedSegments = () => {
        if (!this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return;
        }
        this.showUsedSegments = !this.showUsedSegments;
    };

    @action toggleSegmentGroupOrGapSelection = (groupOrGap: TimeCastSegmentGroup | TimeCastSegmentGap) => {
        if (!this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return;
        }
        const index = this.selectedSegments.indexOf(groupOrGap);
        if (index !== -1) {
            this.selectedSegments.splice(index, 1);
        } else if (
            // only select segments if they are a gap
            groupOrGap.gap ||
            // or if they aren't associated with a time entry
            !groupOrGap.segments.some(s => !!s.associatedTimeEntry)
        ) {
            this.selectedSegments.push(groupOrGap);
        }
        this.selectedSegments = this.selectedSegments.slice();
    };
    @action toggleTimerSelection = (segment: TimerChunk) => {
        const index = this.selectedTimerSegments.findIndex(seg => seg.id === segment.id);
        if (index !== -1) {
            this.selectedTimerSegments.splice(index, 1);
        } else {
            if (!segment.timeEntryId) {
                this.selectedTimerSegments.push(segment);
            }
        }
        this.selectedTimerSegments = this.selectedTimerSegments.slice();
    };
    
    isSelected = (groupOrGap: TimeCastSegmentGroup | TimeCastSegmentGap): boolean => {
        return this.selectedSegments.some(g => g === groupOrGap)
            || this.selectedSegments.some(g => !g.gap
                && !groupOrGap.gap
                && groupOrGap.segments.length === g.segments.length
                && groupOrGap.segments.every((s, i) => s.id === g.segments[i].id
                    && s.foreignIdentifier === g.segments[i].foreignIdentifier
                )
            );
    };
    isSelectedTimerSegment = (segment: TimerChunk): boolean => {
        return this.selectedTimerSegments.some((s) => s.id === segment.id);
    };
    
    @action toggleSegmentViewMode = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        if (!this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return;
        }
        this.segmentViewMode = SegmentViewMode[evt.target.value];
        if (this.segmentViewMode === SegmentViewMode.APPLICATION_TYPE) {
            if (Object.keys(this.segmentGroupsByApplication).length !== 0) {
                return;
            } else {
                this.computeSegmentGroupsByApplication();
            }
        } else if (this.segmentViewMode === SegmentViewMode.TIME_TYPE) {
            if (Object.keys(this.segmentGroupsByTime).length !== 0) {
                return;
            } else {
                this.computeSegmentGroupsByTime();
            }
        }
        this.refreshSegmentsView();
    }
    
    @action deselectAllSegments = () => {
        if (!this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return;
        }
        this.selectedSegments = [];
        this.selectedTimerSegments = [];
        this.refreshSegmentsView();
    };
    @action.bound deleteSegments = async () => {
        const confirmDelete = await this.confirm('dialog.confirm.message.delete');
        if (!confirmDelete) {
            return;
        }
        this.isTCViewInProgress = true;
        if (this.selectedSegments.length > 0) {
            await this.deleteTimeCastSegments();
            this.selectedSegments = [];
        }
        if (this.selectedTimerSegments.length > 0) {
            await this.deleteTimerSegments();
            this.selectedTimerSegments = [];
        }
        this.rootStore.snackbarStore.triggerSnackbar('app.snackbar.info.deleted');
        this.isTCViewInProgress = false;
    };
    deleteTimerSegments = async () => {
        let deleteable: TimerChunk[] = this.selectedTimerSegments.map((segment) => {
            const del = {
                ...segment,
                deleted: true
            };
            return del;
        });

        const apiResult: ApiResult<TimerChunk>[] = await this.rootStore.api.Timer.updateChunks(deleteable);
        const deleted: TimerChunk[] = [];
        const failedSegments: TimerChunk[] = [];
        apiResult.map(api => {
            if (!api.status.failed) {
                deleted.push(api.object);
            } else {
                failedSegments.push(api.object);
            }
        });
        if (failedSegments.length > 0) {
            this.rootStore.snackbarStore.triggerSnackbar(`${apiResult.map(f => f.status.message).join(',')}`);
        }
        this.setTimersForDay();
    }
    deleteTimeCastSegments = async () => {
        if (!this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return;
        }
        let deleteable: TimeCastSegment[] = [];
        this.selectedSegments.forEach((segmentGroup) => {
            if (!segmentGroup.gap) { // Delete only segment groups not gaps

                const del = segmentGroup.segments.map((seg) => {
                    seg.deleted = true;
                    return seg;
                });
                deleteable = deleteable.concat(del);
            }
        });

        const apiResult: ApiResult<TimeCastSegment>[] = await this.rootStore.api.TimeCast.deleteSegments(deleteable);
        const deleted: TimeCastSegment[] = [];
        const failedSegments: TimeCastSegment[] = [];
        apiResult.map(api => {
            if (!api.status.failed) {
                deleted.push(api.object);
            } else {
                failedSegments.push(api.object);
            }
        });
        if (failedSegments.length > 0) {
            this.rootStore.snackbarStore.triggerSnackbar(`${apiResult.map(f => f.status.message).join(',')}`);
        }
        this.renderSegments(deleted);
    }
    
    @computed get hasSelectedAtLeastOneGroup(): boolean {
        return this.selectedSegments.some(s => !s.gap) || this.selectedTimerSegments.length > 0;
    }

    @action refreshSegmentsView = () => {
        this.segmentGroupsByChronology = this.segmentGroupsByChronology.slice();
        this.segmentGroupsByApplication = {...this.segmentGroupsByApplication};
        this.segmentGroupsByTime = {...this.segmentGroupsByTime};
    }

    @action.bound @debounce(150, {leading: false}) async debouncedRefreshSegments(date: DateTime = this.mainCalendarDate) {
        this.refreshSegments(date);
    }

    getAllWindowsIntegratedSegments = (segments: TimeCastSegment[]) => {
        let filteredSegments = segments.filter((segment) => {
            if ((segment.data as DesktopCaptureData).app === WindowsIntegratedProgramTypes.MS_WORD ||
                (segment.data as DesktopCaptureData).app === WindowsIntegratedProgramTypes.MS_EXCEL ||
                (segment.data as DesktopCaptureData).app === WindowsIntegratedProgramTypes.MS_POWERPOINT ||
                (segment.data as DesktopCaptureData).app === WindowsIntegratedProgramTypes.MS_ACCESS ||
                (segment.data as DesktopCaptureData).app === WindowsIntegratedProgramTypes.MS_PUBLISH) {
                    return true;
                } else {
                    return false;
                }
        });
        return filteredSegments;
    }

    // tslint:disable-next-line:no-any
    groupByWindowId = (segments: TimeCastSegment[], prop: any) => {
        var groups = {};
        segments.forEach((segment) => {
            var group = JSON.stringify(prop(segment));
            groups[group] = groups[group] || [];
            groups[group].push(segment);
        });
        return Object.keys(groups).map((group) => {
            return groups[group];
        });
    }

    @action.bound async refreshSegments(date: DateTime = this.mainCalendarDate) {
        try {
            this.segmentsLoading = true;
            if (!this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
                return;
            }
            if (this.calendarMode !== ViewMode.DAY) {
                return;
            }
            if (!this.tcRefreshable) {
                return ;
            }
            this.selectedSegments = [];
            this.expandedApplications = [];
            this.segmentGroupsByChronology = [];
            this.segmentGroupsByApplication = {};
            this.segmentGroupsByTime = {};
            // this.segmentsLoading = true;
            this.currentDaySegments = [];
            this.expandedTimers = [];
            this.dayViewTimers = [];
            this.selectedTimerSegments = [];
            let lastModifiedIndex = 0;
            const startTime = new Date(date.year, date.month - 1, date.day);
            const millisecondsInADay = 1000 * 60 * 60 * 24;
            const endTime = new Date(startTime.getTime() + millisecondsInADay - 1);

            const extractFromLocalOutlook = this.rootStore.appStore.features.EpochConfigTimeCastPullFromLocalOutlook;
            this.currentDaySegments = await this.rootStore.api.TimeCast.getSegmentsBetween(
                startTime.toISOString(),
                endTime.toISOString(),
                { extractFromLocalOutlook }
            );
            if (this.currentDaySegments.length > 0) {
                let windowsIntegratedSegments = this.getAllWindowsIntegratedSegments(this.currentDaySegments);
                let groupedSegmentsByWindowsId = this.groupByWindowId(windowsIntegratedSegments, (segment: TimeCastSegment) => {
                    return [(segment.data as DeprecatedDesktopCaptureData).windowid];
                });
                let lastModifiedWindowsIntegratedSegments = groupedSegmentsByWindowsId.map((windowIdSegments) => {
                    if (windowIdSegments.length > 1) {
                        windowIdSegments.sort((s1: TimeCastSegment, s2: TimeCastSegment) => {
                            if (s2.endTime > s1.endTime) {
                                return -1;
                            }
                            if (s1.endTime > s2.endTime) {
                                return 1;
                            }
                            return 0;
                        });
                    }
                    lastModifiedIndex = windowIdSegments.length - 1;
                    if (windowIdSegments.length > 1) {
                        let title = (windowIdSegments[lastModifiedIndex].data as DesktopCaptureData).title;
                        // Except for word and excel, we dont see title change when existing window applications
                        // from unsaved dialog so checking for word and excel titles only.
                        if (title.toLowerCase() === 'word' || title.toLowerCase() === 'excel') {
                            lastModifiedIndex = lastModifiedIndex - 1;
                        }
                    }
                    let lastModifiedSegment = windowIdSegments[lastModifiedIndex];
                    return lastModifiedSegment;
                });
                for (let i = 0; i < lastModifiedWindowsIntegratedSegments.length; i++) {
                    this.currentDaySegments.map((segment) => {
                        let windowId = (lastModifiedWindowsIntegratedSegments[i].data as DeprecatedDesktopCaptureData).windowid;
                        let title = (lastModifiedWindowsIntegratedSegments[i].data as DesktopCaptureData).title;
                        if ((segment.data as DeprecatedDesktopCaptureData).windowid === windowId) {
                            (segment.data as DesktopCaptureData).title = title;
                        }
                    });
                }
            }
            await this.renderSegments(this.currentDaySegments);
            this.setTimersForDay();
            this.segmentsLoading = false;
        } catch (e) {
            logger.error('Error refresing segments', e);
        }
    };
    renderSegments = async (segments: TimeCastSegment[]) => {
        let segmentGroups;
        segmentGroups = this.mergeSegments(this.concreteSegments);
        segmentGroups = this.addGaps(segmentGroups, this.mainCalendarDate.startOf('day'));
        this.segmentGroupsByChronology = segmentGroups;

        if (this.segmentViewMode === SegmentViewMode.APPLICATION_TYPE) {
            // Final list of segment groups by application type
            this.computeSegmentGroupsByApplication();
        } else if (this.segmentViewMode === SegmentViewMode.TIME_TYPE) {
            // Final list of segment groups by time
            this.computeSegmentGroupsByTime();
        }
        this.segmentsLoading = false;
    }
    
    computeSegmentGroupsByApplication() {
        let groups = {};
        try {
            for (let i = 0; i < this.segmentGroupsByChronology.length; i++) {
                const group = this.segmentGroupsByChronology[i] as TimeCastSegmentGroup;
                // ignore gaps
                if (group.gap) {
                    continue;
                }
                const existingEntries = groups[group.application];
                if (existingEntries) {
                    existingEntries.push(group);
                } else {
                    groups[group.application] = [group];
                }
            }
            this.segmentGroupsByApplication = groups;
        } catch (e) {
            logger.error('Error grouping TimeCast segments by application', e);
        }
    }

    computeSegmentGroupsByTime() {
        let groups = {};
        try {
            for (let i = 0; i < this.segmentGroupsByChronology.length; i++) {
                const group = this.segmentGroupsByChronology[i] as TimeCastSegmentGroup;
                // ignore gaps
                if (group.gap) {
                    continue;
                }
                let startTimeGroup = `${(new Date(group.startTime.toISO())).toLocaleString('local', {
                    hour: '2-digit'
                })}`;
                const existingTimeGroups = groups[startTimeGroup];
                if (existingTimeGroups) {
                    groups[startTimeGroup] = existingTimeGroups.concat(group);
                } else {
                    groups[startTimeGroup] = [group];
                }
            }
            this.segmentGroupsByTime = groups;
        } catch (e) {
            logger.error('Error grouping TimeCast segments by time', e);
        }
    }

    @action async associatedCallback(apiResult: AssociateApiResult) {
        if (!this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return;
        }
        this.segmentsLoading = true;
        let savedTCSegments: TimeCastSegment[] = [];
        let failedTCSegments: TimeCastSegment[] = [];
        let savedTimerSegments: TimerChunk[] = [];
        let failedTimerSegments: TimerChunk[] = [];
        const {
            TimeEntryApi,
            TimerChunkApis,
            TimeCastApis
        } = apiResult;
        if (TimeEntryApi.status.failed) {
            this.rootStore.snackbarStore.triggerSnackbar(TimeEntryApi.status.message);
            this.isTCViewInProgress = false;
            return;
        } else {
            this.rootStore.snackbarStore.triggerSnackbar('app.snackbar.info.saved');
        }
        if (TimerChunkApis) {
            TimerChunkApis.map(api => {
                if (!api.status.failed) {
                    savedTimerSegments.push(api.object);
                } else {
                    failedTimerSegments.push(api.object);
                }
            });
        }
        if (TimeCastApis) {
            TimeCastApis.map(api => {
                if (!api.status.failed) {
                    savedTCSegments.push(api.object);
                } else {
                    failedTCSegments.push(api.object);
                }
            });
        }
        if (failedTimerSegments.length > 0 || failedTCSegments.length > 0) {
            this.rootStore.snackbarStore.triggerSnackbar(`Error updating following segments: 
                ${failedTCSegments.map(f => f.id).join(',')}
                ${failedTimerSegments.map(f => f.id).join(',')}
            `);
        }
        if (savedTCSegments.length > 0) {
            this.renderSegments(savedTCSegments);
        }
        if (savedTimerSegments.length > 0) {
            this.setTimersForDay();
        }
        this.segmentsLoading = false;
    }
    // Update segments when associated or dissocated or deleted
    renderTimerSegments = (chunks: TimerChunk[]) => {
        let timers = this.dayViewTimers;
        chunks.forEach((chunk, idx) => {
            const index = timers.findIndex(t => t.id === chunk.timerId);
            if (index !== -1) {
                const chkIdx = timers[index].chunks.findIndex(c => c.id === chunk.id);
                if (chkIdx !== -1) {
                    timers[index].chunks[chkIdx] = chunk;
                } else {
                    timers[index].chunks.push(chunk);
                }
                if (chunk.deleted) {
                    timers[index].chunks.splice(idx, 1);
                }
            }
        });
        this.dayViewTimers = timers.slice();
    };
    @action.bound openTimeEntryFromTimerSegment = (chunk: TimerChunk) => {
        this.rootStore.timerStore.openTimeEntry(chunk.timeEntryId!, chunk.timerId);
    }
    /**
     * @param groups
     *        These groups should be sorted by startTime.
     * @param dateTime
     *        The day for which to fill gaps
     * @return
     *        Array of the same groups, but with gaps of time added between 9AM-5PM.
     */
    addGaps(groups: TimeCastSegmentGroup[], dateTime: DateTime): Array<TimeCastSegmentGap | TimeCastSegmentGroup> {
        if (!this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return [];
        }
        const array: Array<TimeCastSegmentGap | TimeCastSegmentGroup> = [];
        // define 9AM - 5PM in seconds
        const date = dateTime.startOf('day');
        let gapStartMinute = date.plus(Duration.fromObject({ hours: 0 })).diff(date, 'minutes').get('minutes');
        const gapEndMinute = date.plus(Duration.fromObject({ hours: 24 })).diff(date, 'minutes').get('minutes');
        const groupsOutsideGap: TimeCastSegmentGroup[] = [];
        // Traverse groups and find gaps between them based
        // on the start and end points defined above
        try {
            for (let i = 0; i < groups.length; i++) {
                const group = groups[i];
                const st = new Date(group.startTime.toISO());
                const et = new Date(group.endTime.toISO());
                const groupStartMinutes = st.getMinutes() + 60 * st.getHours();
                const groupEndMinutes = et.getMinutes() + 60 * et.getHours();
                if (groupStartMinutes <= gapStartMinute && groupEndMinutes <= gapStartMinute) {
                    // group occurs before the entire gap
                    array.push(group);
                } else if (groupStartMinutes <= gapStartMinute && groupEndMinutes >= gapStartMinute && groupEndMinutes <= gapEndMinute) {
                    // group covers part of the beginning
                    gapStartMinute = groupEndMinutes;
                    array.push(group);
                } else if (groupStartMinutes >= gapStartMinute && groupStartMinutes <= gapEndMinute && groupEndMinutes >= gapStartMinute && gapStartMinute <= gapEndMinute) {
                    // group covers somewhere in the middle (and could be covering the entire rest of the gap!)
                    if (groupStartMinutes > gapStartMinute) {
                        // there's a gap before the group
                        const sHour = Math.floor(gapStartMinute / 60);
                        const sMinute = gapStartMinute - (Math.floor(gapStartMinute / 60) * 60);
                        const eHour = Math.floor(groupStartMinutes / 60);
                        const eMinute = groupStartMinutes - Math.floor(groupStartMinutes / 60) * 60;
                        // console.log(`${groupStartMinutes}// group covers part of the middle (${sHour}:${sMinute} -> ${eHour}:${eMinute})`)
                        const gap: TimeCastSegmentGap = {
                            gap: true,
                            startTime: DateTime.local(date.year, date.month, date.day, sHour, sMinute),
                            endTime: DateTime.local(date.year, date.month, date.day, eHour, eMinute)
                        };
                        array.push(gap);
                    }
                    array.push(group);
                    gapStartMinute = groupEndMinutes;
                } else if (groupStartMinutes >= gapStartMinute && groupStartMinutes <= gapEndMinute && groupEndMinutes >= gapEndMinute) {
                    if (gapStartMinute < groupEndMinutes) {
                        // group covers part of the end
                        const sHour = Math.floor(gapStartMinute / 60);
                        const sMinute = gapStartMinute - (Math.floor(gapStartMinute / 60) * 60);
                        const eHour = Math.floor(groupStartMinutes / 60);
                        const eMinute = groupStartMinutes - Math.floor(groupStartMinutes / 60) * 60;
                        // console.log(`${groupStartMinutes}// group covers part of the end (${sHour}:${sMinute} -> ${eHour}:${eMinute})`)
                        const gap: TimeCastSegmentGap = {
                            gap: true,
                            startTime: DateTime.local(date.year, date.month, date.day, sHour, sMinute),
                            endTime: DateTime.local(date.year, date.month, date.day, eHour, eMinute)
                        }
                        array.push(gap);
                        gapStartMinute = groupEndMinutes;
                    }
                    array.push(group);
                } else if (groupStartMinutes >= gapEndMinute && groupEndMinutes >= gapEndMinute) {
                    // group is outside the gap completely.
                    groupsOutsideGap.push(group);
                }
            }
            if (gapStartMinute < gapEndMinute) {
                // fill any remaining gap
                const sHour = Math.floor(gapStartMinute / 60);
                const sMinute = gapStartMinute - (Math.floor(gapStartMinute / 60) * 60);
                const eHour = Math.floor(gapEndMinute / 60);
                const eMinute = gapEndMinute - Math.floor(gapEndMinute / 60) * 60;
                // console.log(`-// fill any remaining gap (${sHour}:${sMinute} -> ${eHour}:${eMinute})`)
                const gap: TimeCastSegmentGap = {
                    gap: true,
                    startTime: DateTime.local(date.year, date.month, date.day, sHour, sMinute),
                    endTime: DateTime.local(date.year, date.month, date.day, eHour, eMinute)
                }
                array.push(gap)
                // gapStartMinute = gapEndMinute;
            }
            array.push(...groupsOutsideGap);
            return array;
        } catch (e) {
            logger.info('TimeCast, Error adding gaps', e);
            return [];
        }
    }
    
    /**
     * @param segmentList
     *        These are grouped together based on their properties.
     * @return Groups of time segments which are sorted by ascending time.
     */
    @computed get concreteSegments(): TimeCastSegment[] {
        const segments: TimeCastSegment[] = [];
        this.currentDaySegments.map((seg) => {
            if (seg.foreignIdentifier && seg.type.startsWith('VIRTUAL_')) {
                const existing = segments.find(f => (f.foreignIdentifier === seg.foreignIdentifier));
                if (existing) {
                    return;
                }
            }
            if (!seg.deleted) {
                segments.push(seg);
            }
        });
        return segments;
    }
    mergeSegments(segmentList: TimeCastSegment[]): TimeCastSegmentGroup[] {
        if (!this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return [];
        }
        if (!this.timeCastSettings) {
            return [];
        }
        let minimumInteral = this.timeCastSettings.getMinimumIntervalSeconds(); // seconds
        let mergeInterval = this.timeCastSettings.getMergeIntervalSeconds(); // seconds
        // Make sure there are segments to operate on
        if (!segmentList || segmentList.length === 0) {
            return [];
        }
        // 1. Filter out segments that are:
        //      - deleted
        //      - are of type VIRTUAL_* but have a concrete segment
        
        // segmentList.map(seg => {
        //     if (seg.foreignIdentifier && seg.type.startsWith('VIRTUAL_')) {
        //         const existing = segments.find(f => {
        //             return f.foreignIdentifier === seg.foreignIdentifier
        //         });
        //         if (existing) {
        //             return;
        //         }
        //     }
        //     if (!seg.deleted) {
        //         segments.push(seg);
        //     }
        // })
        // Sort the segments by startTime
        segmentList.sort((a, b) => {
            return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
        });
        // 2. Merge segments within $mergeInterval seconds of each other
        try {
            const segmentGroups = segmentList.reduce((mergedSegments, segment) => {
                const segmentStartTime = DateTime.fromISO(segment.startTime);
                const segmentEndTime = DateTime.fromISO(segment.endTime);
                if (typeof segment.data as unknown === 'string') {
                    segment.data = JSON.parse(segment.data as unknown as string);
                }
                if (mergedSegments.length === 0) {
                    mergedSegments.push({
                        segments: [segment],
                        startTime: segmentStartTime,
                        endTime: segmentEndTime,
                        groupId: TimeCast.getSegmentGroupId(segment),
                        title: TimeCast.getSegmentGroupTitle(segment),
                        gap: false,
                        application: TimeCast.getSegmentGroupApplicationName(segment),
                        type: segment.type,
                        duration: segmentEndTime.diff(segmentStartTime, 'hours').get('hours')
                    });
                    return mergedSegments;
                }
                let merged = false;
                for (let i = 0; i < mergedSegments.length; i++) {
                    const mergedSegment = mergedSegments[i];
                    if (
                        // don't merge non-DESKTOP_CAPTURE segments
                        segment.type !== 'DESKTOP_CAPTURE'
                        // don't merge used segments with unused segments, and vice versa
                        || !!segment.associatedTimeEntry !== !!mergedSegment.segments[0].associatedTimeEntry
                        // don't merge segments which are completely unrelated
                        || TimeCast.getSegmentGroupId(segment) !== mergedSegment.groupId
                    ) {
                        continue;
                    }
                    const isWithinMergeGroup = mergedSegment.startTime <= segmentStartTime
                        && mergedSegment.endTime >= segmentStartTime;
                    const isWithinMergeThreshold = mergedSegment.startTime <= segmentStartTime
                        && mergedSegment.endTime <= segmentStartTime
                        && segmentStartTime.diff(mergedSegment.endTime, 'seconds').get('seconds') <= mergeInterval;
                    if (isWithinMergeGroup || isWithinMergeThreshold) {
                        // merge them together
                        mergedSegment.endTime = segmentEndTime;
                        mergedSegment.duration += segmentEndTime.diff(segmentStartTime, 'hours').get('hours');
                        mergedSegment.segments.push(segment);
                        merged = true;
                        break;
                    }
                }
                if (!merged) {
                    // couldn't merge the segments, so make a new group
                    mergedSegments.push({
                        segments: [segment],
                        startTime: segmentStartTime,
                        endTime: segmentEndTime,
                        groupId: TimeCast.getSegmentGroupId(segment),
                        title: TimeCast.getSegmentGroupTitle(segment),
                        gap: false,
                        application: TimeCast.getSegmentGroupApplicationName(segment),
                        type: segment.type,
                        duration: segmentEndTime.diff(segmentStartTime, 'hours').get('hours')
                    });
                }
                return mergedSegments;
            }, [] as TimeCastSegmentGroup[]);
            // 3. Filter out segments that are less than $minimumInterval
            return segmentGroups.filter(group => group.type !== 'DESKTOP_CAPTURE'
                || group.endTime.diff(group.startTime, 'seconds').get('seconds') >= minimumInteral
            );
        } catch (e) {
            logger.info('Timecast, Error merging segments', e);
            return [];
        }
    }
    
    @computed get filteredChronologicalSegments() {
        let filtered = this.segmentGroupsByChronology;
        // Filter segment groups based on:
        // 1) whether gaps should be shown
        if (!this.showSegmentGaps) {
            filtered = filtered.filter(g => !g.gap);
        }
        // 2) whether used segments should be shown
        if (!this.showUsedSegments) {
            filtered = filtered.filter(g => g.gap || g.segments.every(s => !s.associatedTimeEntry));
        }
        // 3) Deleted segments
        filtered = filtered.filter(g => g.gap || g.segments.every(seg => !seg.deleted));
        return filtered;
    }
    
    @computed get filteredApplicationTypeSegments() {
        let filtered = {...this.segmentGroupsByApplication};
        for (let application of Object.keys(filtered)) {
            let applicationSegments = this.segmentGroupsByApplication[application];
            // Filter out segments based on:
            // 1) whether used segments should be shown
            if (!this.showUsedSegments) {
                applicationSegments = applicationSegments.filter(g => g.segments.every(s => !s.associatedTimeEntry));
            }
            // 2) Whether it is deleted
            applicationSegments = applicationSegments.filter((g) => g.segments.every(s => !s.deleted));
            filtered[application] = applicationSegments;
        }
        return filtered;
    }
    
    @computed get filteredTimeTypeSegments() {
        let filtered = {...this.segmentGroupsByTime};
        for (let timeframe of Object.keys(filtered)) {
            let timeframeSegments = this.segmentGroupsByTime[timeframe];
            // Filter out segments based on:
            // 1) whether used segments should be shown
            if (!this.showUsedSegments) {
                timeframeSegments = timeframeSegments.filter(g => g.segments.every(s => !s.associatedTimeEntry));
            }
            timeframeSegments = timeframeSegments.filter((g) => g.segments.every(s => !s.deleted));
            filtered[timeframe] = timeframeSegments
        }
        return filtered;
    }
    
    @action.bound selectSameWindows = (groupOrGap: TimeCastSegmentGroup | TimeCastSegmentGap) => {
        if (!this.rootStore.appStore.features.EpochConfigTimeCastEnabled) {
            return;
        }
        // ignore request if attempting to select all gaps
        if (groupOrGap.gap) {
            return;
        }
        try {
            let sameWindowSegments = this.filterSameWindowSegments(groupOrGap , this.filteredChronologicalSegments);
            let selectedSegments = this.selectedSegments.slice();
            let sameWindowSelectedSegments = this.filterSameWindowSegments(groupOrGap , selectedSegments);
            sameWindowSegments.map((seg) => {
                let idx = selectedSegments.indexOf(seg);
                if (sameWindowSegments.length !== sameWindowSelectedSegments.length) {
                    if (idx === -1) {
                        selectedSegments.push(seg);
                    }
                } else {
                    selectedSegments.splice(idx, 1);
                }
            });
            this.selectedSegments = selectedSegments;
        } catch (e) {
            logger.info('TimeCast, Error in selecting same windows', e);
        }
    };

    filterSameWindowSegments = (groupOrGap: TimeCastSegmentGroup | TimeCastSegmentGap , 
                                segments: (TimeCastSegmentGroup | TimeCastSegmentGap)[]) => {
                                    
        let filteredSegments = segments.filter((grp) =>
            // ignore gap segments
            !grp.gap &&
            // only include segments of the same group
            (groupOrGap as TimeCastSegmentGroup).groupId === grp.groupId &&
            // ignore groups where every segment is already used
            !(groupOrGap as TimeCastSegmentGroup).segments.every(s => !!s.associatedTimeEntry)
        );
        return filteredSegments;
    }

    /*=====================================================================*/

    setTimeEntryRange = (date: DateTime) => {
        if (this.calendarMode === ViewMode.MONTH) {
            this.setRange(
                date.startOf('month').minus({ days: 6 }),
                date.endOf('month').plus({days: 6})
            );
        } else if (this.calendarMode === ViewMode.DAY) {
            this.setRange(
                date.startOf('day'),
                date.endOf('day')
            );
        } else {
            this.setRange(
                getStartOf(date, 'week', this.rootStore.appStore.startOfWeek),
                getEndOfWeek(date, this.rootStore.appStore.startOfWeek)
            );
        }
    };

    @action setToday = (date: DateTime, today?: boolean) => {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1;
        const day = now.getDate();
        this.setMainDate(DateTime.local(year, month, day), today);
        this.dayCountsIfDayOrWeek();
    };
    
    @action setTodayEntries = (date: DateTime, today?: boolean) => {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1;
        const day = now.getDate();
        const local = DateTime.local(year, month, day);
        this.setToday(date, today);
        this.setTimeEntryRange(local);
    }

    @action setMainDate = (date: DateTime, today?: boolean) => {
        const adjustedDate = date.toUTC().startOf('day');
        if (this.calendarMode === ViewMode.DAY || today) {
            this.setSelectedDates([date]);
        }
        this.mainCalendarDate = adjustedDate;
        this.miniCalendarMainDate = adjustedDate;
        // if (shouldRefreshSegmentsAndEntries) {
        //     this.setTimeEntryRange(this.mainCalendarDate);
        //     this.debouncedRefreshSegments(date);
        // }
    };

    @action changeViewMode = async (viewMode: ViewMode) => {
        // Wait for unsaved dialog only when there are any dirty entries
        const selectedFirstDate = this.selectedDates[0];
        const changeScope: boolean = await this.rootStore.canIChangeScope();
        if (changeScope && viewMode !== this.calendarMode) {
            this.setViewMode(viewMode);
            this.selectedEntryIds = [];
            this.setToggleTimers(false);
            this.validationState.clear();
            let selectedDate: DateTime = selectedFirstDate;
            if ((this.calendarMode === ViewMode.WEEK || this.calendarMode === ViewMode.GRID) &&
                !selectedFirstDate.startOf('month')
                .equals(this.selectedDates[this.selectedDates.length - 1].startOf('month'))
            ) {
                const sorted = this.selectedDates.sort((d1, d2) => (d1.toMillis() - d2.toMillis()));
                selectedDate = sorted[0];
            }
            this.setTimeEntryRange(selectedDate);
            if (this.calendarMode === ViewMode.WEEK || this.calendarMode === ViewMode.DAY) {
                this.getTimeEntriesCount(
                    selectedDate.startOf('month').minus({ days: 6 }),
                    selectedDate.endOf('month').plus({ days: 6 })
                );
            }
            this.setMainDate(selectedFirstDate);
            this.setExpandedTimeEntries([]);
        }
    };

    setViewMode(viewMode: ViewMode) {
        this.calendarMode = viewMode;
        this.selectedSegments = [];
    }

    @action stepMainDate = async (amount: number) => {
        if (await this.rootStore.canIChangeScope()) {
            this.dayViewTimers = [];
            this.setToggleTimers(false);
            let from = this.mainCalendarDate.startOf('month'), 
                until = this.mainCalendarDate.endOf('month');
            switch (this.calendarMode) {
                case ViewMode.MONTH:
                    this.setMainDate(this.mainCalendarDate.startOf('month').plus({
                        months: amount
                    }));
                    from = this.mainCalendarDate.startOf('month').minus({ days: 6 });
                    until = this.mainCalendarDate.endOf('month').plus({ days: 6 });
                    break;
                case ViewMode.WEEK:
                case ViewMode.GRID:
                    const adjDate = this.mainCalendarDate.plus({
                        weeks: amount
                    });
                    this.setMainDate(adjDate);
                    from = getStartOf(adjDate, 'week', this.rootStore.appStore.startOfWeek);
                    until = getEndOfWeek(adjDate, this.rootStore.appStore.startOfWeek);
                    if (this.calendarMode === ViewMode.WEEK) {
                        this.getTimeEntriesCount(
                            adjDate.startOf('month').minus({ days: 6 }),
                            adjDate.endOf('month').plus({ days: 6 })
                        );
                    }
                    break;
                default:
                    this.setMainDate(this.mainCalendarDate.plus({
                        days: amount
                    }));
                    from = this.mainCalendarDate.startOf('day');
                    until = this.mainCalendarDate.endOf('day');
                    break;
            }
            this.setRange(from, until);
        }
    };

    @action stepSideBarDate = (amount: number) => {
        this.miniCalendarMainDate = this.miniCalendarMainDate.plus({
            months: amount
        });
        let date: DateTime = DateTime.utc(this.loc.year, this.loc.month, this.loc.day);
        if (this.miniCalendarMainDate < date) {
            // this.setRange(
            //     this.miniCalendarMainDate.startOf('month').minus({ days: 6 }),
            //     date.endOf('month').plus({ days: 6 })
            // )
            if (this.calendarMode !== ViewMode.MONTH) {
                this.getTimeEntriesCount(
                    this.miniCalendarMainDate.startOf('month').minus({ days: 6 }),
                    date.endOf('month').plus({ days: 6 })
                );
            }
        } else {
            // this.setRange(
            //     date.startOf('month').minus({ days: 6 }),
            //     this.miniCalendarMainDate.endOf('month').plus({ days: 6 })
            // )
            if (this.calendarMode !== ViewMode.MONTH) {
                this.getTimeEntriesCount(
                    date.startOf('month').minus({ days: 6 }),
                    this.miniCalendarMainDate.endOf('month').plus({ days: 6 })
                );
            }
            
        }
    };

    @action setSelectedDates = async (dates: DateTime[], shouldRefreshSegments: boolean = true, mini?: boolean) => {
        const dayViewDate = dates[0];
        if (await this.rootStore.canIChangeScope()) {
            this.setToggleTimers(false);
            this.validationState.clear();
            this.selectedDates = dates.map(d => d.toUTC().startOf('day'));
            this.setSelectedTimeEntries([]);
            this.setExpandedTimeEntries([]);
            if (mini) {
                this.setMainDate(this.selectedDates[0]);
                const from = getStartOf(dayViewDate, 'week', this.rootStore.appStore.startOfWeek);
                if (this.calendarMode === ViewMode.WEEK) {
                    this.setRange(
                        from, 
                        getEndOfWeek(dayViewDate, this.rootStore.appStore.startOfWeek)
                    );
                } else if (this.calendarMode === ViewMode.DAY) {
                    this.setRange(dayViewDate.startOf('day'), dayViewDate.endOf('day'));
                }
            }
            if (shouldRefreshSegments) {
                this.debouncedRefreshSegments(dayViewDate);
            }
        }
    };

    @action dayCountsIfDayOrWeek = () => {
        if (this.calendarMode === ViewMode.DAY || this.calendarMode === ViewMode.WEEK) {
            this.getTimeEntriesCount(
                this.mainCalendarDate.startOf('month').minus({ days: 6 }),
                this.mainCalendarDate.endOf('month').plus({ days: 6 })
            );
        }
    }

    @action addSelectedDate = (date: DateTime) => {
        if (this.selectedDates.filter(d => (+d === +date)).length > 0) {
            if (this.selectedDates.length === 1) {
                return;
            }
            this.setSelectedDates(this.selectedDates.filter(d => (+d !== +date)));
            return;
        }
        this.setSelectedDates(this.selectedDates.concat([date.toUTC().startOf('day')]));
        this.setSelectedTimeEntries([]);
        this.setExpandedTimeEntries([]);
        this.debouncedRefreshSegments(date);
    };

    @action resetDates = async () => {
        let date: DateTime = DateTime.utc(this.loc.year, this.loc.month, this.loc.day);
        await this.setSelectedDates([date], false);
        // await this.setMainDate(date, true)
    }

    @action reset = async () => {
        this.setViewMode(ViewMode.MONTH);
        await this.resetDates();
        this.timeCastSettings = undefined;
        this.allPrograms = [];
        this.segmentGroupsByChronology = [];
        this.segmentGroupsByApplication = {};
        this.segmentGroupsByTime = {};
        this.segmentsLoading = true;
        this.selectedSegments = [];
        this.segmentViewMode = SegmentViewMode.CHRONOLOGICAL;
        this._showSegmentGaps = true;
        this.showUsedSegments = false;
        this.expandedApplications = [];
        this.expandedTimers = [];
    }
    /**
     * filters date map of selected dates
     * @returns map of time entries grouped by work date time
     */
    @computed get selectedTimeEntriesMap(): Map<string, TimeEntry[]> {
        let result = new Map();
        this.selectedDates.map((date) => {
            let iso = date.toISODate();
            let meta = this.dateMetaDataMap.get(iso);
            if (meta) {
                // tslint:disable-next-line:no-any
                meta.entries.sort((a: any, b: any) => {
                    return  DateTime.fromISO(a.lastModified) > DateTime.fromISO(b.lastModified) ? -1 : 1;
                });
                result.set(iso, meta.entries);
            }
        });
        return result;
    }

    @action setRange = async (start: DateTime, end: DateTime) => {
        this.startDate = start;
        this.endDate = end;
        if (this.calendarMode !== ViewMode.GRID) {
            await this.loadEntries(this.startDate, this.endDate);
        }
        if (this.rootStore.appStore.features && 
            this.rootStore.appStore.features.EpochConfigTKGoalsEnabled
        ) {
            if (this.calendarMode === ViewMode.MONTH) {
                this.tkGoals = await this.rootStore.api.Session.getTkGoals(this.mainCalendarDate.year);
            }
        } else {
            if (this.calendarMode !== ViewMode.GRID) {
                this.tkHours = await this.rootStore.api.TimeEntry.getTKHours(this.mainCalendarDate);
            }
        }
    };
    @action showTimersPane = async () => {
        this.selectedTimerSegments = [];
        this.toggleTimers = !this.toggleTimers;
        this.setTimersForDay();
    }
    setToggleTimers = (val: boolean) => {
        this.toggleTimers = val;
    }
    setTimersForDay = async () => {
        if (this.toggleTimers && (this.calendarMode === ViewMode.DAY)) {
            this.isTimersUpdating = true;
            const timers = await this.rootStore.api.Timer.getTimersForDay(this.mainCalendarDate);
            this.dayViewTimers = timers.slice();
            this.isTimersUpdating = false;
        }
    }
    @action setFieldLoaderFn = (val: boolean) => {
        this.matterLoading = val;
    }
    @computed get filteredDayViewTimers () {
        let showSubmitted = this.rootStore.appStore.features.EpochConfigTimeSegmentsSubmittedVisible;
        const filtered: ImmutableTimer[] = this.dayViewTimers
            .filter(t => {
                let chunks = t.chunks.filter(ch => {
                    if (ch.deleted) {
                        return false;
                    }
                    if (showSubmitted) {
                        return true;
                    }
                    return !ch.submitted;
                });
                t.chunks = chunks;
                return t.chunks.length >= 0;
            })
            .sort((a, b) => {
                return  DateTime.fromISO(a.lastModified) > DateTime.fromISO(b.lastModified) ? -1 : 1;
            });
        return filtered;
    }
    // Private methods goes here
    private getDateRange(start: DateTime, end: DateTime): DateTime[] {
        const dates = [];
        const startDate = start.toUTC().startOf('day');
        const endDate = end.toUTC().startOf('day');
        for (let d = startDate; !d.equals(endDate); d = d.plus({ days: 1 })) {
            dates.push(d);
        }
        dates.push(endDate);
        return dates;
    }

    private getCurrentMonth(): DateTime[] {
        const start = this.mainCalendarDate.startOf('month');
        const end = this.mainCalendarDate.endOf('month');
        return this.getDateRange(start, end);
    }

    private getCurrentWeek(): DateTime[] {
        const start = getStartOf(this.mainCalendarDate, 'week', this.rootStore.appStore.startOfWeek);
        const end = getEndOfWeek(this.mainCalendarDate, this.rootStore.appStore.startOfWeek);
        return this.getDateRange(start, end);
    }

    private getCurrentDay(): DateTime {
        return this.mainCalendarDate;
    }

    @computed get summaryOfAggregateTotals() {
        if (this.calendarMode === ViewMode.MONTH) {
            return this.getAggregateTotalsFor(this.getCurrentMonth());
        } else if (this.calendarMode === ViewMode.WEEK) {
            return this.getAggregateTotalsFor(this.getCurrentWeek());
        } else if (this.calendarMode === ViewMode.DAY) {
            return this.getAggregateTotalsFor([this.getCurrentDay()]);
        }
        return {
            draft: -1,
            posted: -1,
            billable: -1,
            nonbillable: -1
        };
    }

    @computed get requiredTkHours(): number {
        if (this.rootStore.appStore.features.EpochConfigTKGoalsEnabled) {
            if (this.calendarMode === ViewMode.MONTH) {
                if (this.tkGoals) {
                    const currentMonth: string = Months[this.mainCalendarDate.month];
                    return this.tkGoals[currentMonth];
                }
            }
        } else {
            if (this.tkHours) {
                switch (this.calendarMode) {
                    case ViewMode.WEEK:
                        return this.tkHours.weekHours;
                        break;
                    case ViewMode.DAY:
                        return this.tkHours.dayHours;
                        break;
                    default:
                        return this.tkHours.monthHours;
                        break;
                }
            }
        }
        return 0;
    }
    @computed get captureChunkObservables(): CaptureSegmentsListObservables {
        return {
            expandedApplications: this.expandedApplications,
            selectedSegments: this.selectedSegments.length + this.selectedTimerSegments.length,
            expandedTimeGroups: this.expandedTimeGroups,
            isTCViewInProgress: this.isTCViewInProgress,
        } as CaptureSegmentsListObservables;
    }
}